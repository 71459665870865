import { FeedTab } from "../../../types/pages/newFeedPage/feedTab";
import { options } from "./daysOptions";
import { excludeLeadsDaysOption } from "./excludeLeadsDaysOption";

export const initialTab: FeedTab = {
  title: 'General',
  id: 'general',
  link: '/feed/general',
  disabled: false,
  includeKeywords: [],
  excludeKeywords: [],
  daysOption: options[options.length - 1],
  excludeLeadComentedBeforeDayCount: excludeLeadsDaysOption[1],
  feed: [],
}

export const initialPageForTab = { tabId: 'general', page: 0, hasMore: true };