import React from 'react';
import { Box, CircularProgress } from '@mui/material';

export const LoadingList = () => {
  return (
    <Box sx={{
      height: 220,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <CircularProgress sx={{ color: '#3B2470' }} />
    </Box>
  )
}

