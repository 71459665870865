import * as React from 'react';
import scss from './automation_page.module.scss'
import Box from '@mui/material/Box';
import Header from '../../components/header/Header';
import { useAutomationData } from '../../utils/redux/storeHooks/dataHooks/useAutomationData';
import { useAutomationActions } from '../../utils/redux/storeHooks/actionsHooks/useAutomationActions';
import { NameOfSlider } from '../../types/pages/automationPage/NameOfSlider';
import { AutomationPageActions } from './features/automationPageActions';
import { AutomationPageSettings } from './features/automationPageSettings';
import { AutomationPageInfo } from './features/automationPageInfo';
import { LinearProgress } from '@mui/material';
import { AutomationPageCommentsLeft } from './features/automationPageCommentsLeft';
import { AutomationPageExcludedLeads } from './features/automationPageExcludedLeads';

export default function AutomationPage() {
  const {
    delayBetweenComments,
    delayAutomation,
    scrollStep,
    getAutomationDataloading,
    workingHours,
    limitOfReplyForADay
  } = useAutomationData();
  const {
    setDelayBetweenComments,
    setDelayAutomation,
    setWorkingHours,
    setDailyCommentsQouta,
    setLimitOfReplyForADay
  } = useAutomationActions();

  const handleChangeSlider = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
    name: string
  ) => {
    switch (name) {
      case NameOfSlider.delayBetweenComments:
        if (Array.isArray(newValue)) {
          if (activeThumb === 0) {
            setDelayBetweenComments([Math.min(newValue[0], delayBetweenComments[1] - scrollStep), delayBetweenComments[1]]);
          } else {
            setDelayBetweenComments([delayBetweenComments[0], Math.max(newValue[1], delayBetweenComments[0] + scrollStep)]);
          }
        }
        return;
      case NameOfSlider.delayAutomation:
        if (Array.isArray(newValue)) {
          if (activeThumb === 0) {
            setDelayAutomation([Math.min(newValue[0], delayAutomation[1] - scrollStep), delayAutomation[1]]);
          } else {
            setDelayAutomation([delayAutomation[0], Math.max(newValue[1], delayAutomation[0] + scrollStep)]);
          }
        }
        return;
      case NameOfSlider.workingHours:
        if (Array.isArray(newValue)) {
          if (activeThumb === 0) {
            setWorkingHours([Math.min(newValue[0], workingHours[1] - scrollStep), workingHours[1]]);
          } else {
            setWorkingHours([workingHours[0], Math.max(newValue[1], workingHours[0] + scrollStep)]);
          }
        }
        return;
      case NameOfSlider.dailyCommentsQuota:
        if (typeof newValue === 'number') {
          setDailyCommentsQouta(newValue);
          setLimitOfReplyForADay(limitOfReplyForADay)
        }
        return;
    }
  };

  if (getAutomationDataloading) {
    return (
      <Box className={scss.pageWrapper}>
        <Header />
        <LinearProgress color='inherit' sx={{ marginTop: '-25px', width: '100%' }} />
      </Box>
    )
  }

  return (
    <Box className={scss.pageWrapper}>
      <Header />
      <Box className={scss.contentWrapper}>
        <AutomationPageInfo />
        <Box className={scss.blockWrapper}>
          <AutomationPageExcludedLeads />
        </Box>
        <Box className={scss.blockWrapper}>
          <AutomationPageActions />
          <AutomationPageCommentsLeft />
        </Box>
        <Box className={scss.blockWrapper}>
          <AutomationPageSettings handleChangeSlider={handleChangeSlider} />
        </Box>
      </Box >
    </Box>
  );
}
