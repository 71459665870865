import React from 'react';
import { Avatar, Box, Divider, Typography } from '@mui/material';
import { useTableLeadsData } from '../../../../utils/redux/storeHooks/dataHooks/useTableLeadsData';
import CircleIcon from '@mui/icons-material/Circle';
import UserInformation from '../../../../types/entities/user_information';

type Props = {
  onSelectUserHandler: (user: UserInformation) => void;
  selectUserForParse: UserInformation | null;
}

export const TeamList = ({ onSelectUserHandler, selectUserForParse }: Props) => {
  const { teamList } = useTableLeadsData();

  return (
    <>
      <Box sx={{
        height: 220,
        marginTop: '4px',
        overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px'
      }}
      >
        {teamList.map(user => (
          <Box
            key={user.uidd}
            onClick={() => onSelectUserHandler(user)}
            sx={{
              cursor: 'pointer',
              backgroundColor: (selectUserForParse?.uidd === user?.uidd) ? 'rgba(59,36,112,0.1)' : '#fff',
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '12px',
                padding: '4px'
              }}
            >
              <Avatar
                alt=""
                src={user.profileImg}
                sx={{ width: '40px', height: '40px' }}
              />
              <Box>
                <Typography sx={{ fontFamily: 'Inter', color: '#3B2470', fontSize: 14, fontWeight: 500 }}>
                  {`${user.firstName} ${user.lastName}`}
                </Typography>
                <Typography sx={{
                  fontFamily: 'Inter',
                  color: '#25660E',
                  fontSize: 12,
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <CircleIcon sx={{ color: user.isCookieValidForNow ? '#25660E' : '#DB491F', fontSize: 8, marginRight: '4px' }} />
                  {user.isCookieValidForNow ? 'Active SN' : 'Inactive SN (User is inactive. Refresh the SN page.)'}
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ width: '100%', marginTop: '4px' }} />
          </Box>
        ))}
      </Box>
    </>
  )
}

