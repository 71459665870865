import * as React from 'react';
import { SinglePost } from './SinglePost';
import { Box } from '@mui/material';
import style from '../../newFeedPage.module.scss'
import { useNewFeedData } from '../../../../utils/redux/storeHooks/dataHooks/useNewFeedData';
import { useParams } from 'react-router-dom';
import { useNewFeedActions } from '../../../../utils/redux/storeHooks/actionsHooks/useNewFeedActions';
import { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CustomLinearProgress } from '../../../../utils/pages/newFeedPage/CustomLinearProgress';
import { NoDataAtSearch } from '../../../../components/noDataForSearch/NoDataForSearch';

export const PostsList = () => {
  const { tabs, pagesForTabs, isGetFeedLoading } = useNewFeedData();
  const { getFeed } = useNewFeedActions();
  const { tabId } = useParams();

  const currentTab = tabs.find(tab => String(tab.id) === tabId) || null;
  const currentPagesForTab = pagesForTabs.find(pages => pages.tabId === tabId)

  const onFeedRequestHandler = () => {
    const { page } = currentPagesForTab;

    const pageFrom = page === 0 ? 0 : page * 10;
    const pageTo = pageFrom + 10;
    //This "if" need for check if in tabs less feed than 10 
    //prevent load from 0 to 10 pagination
    //Because first pagination load from getAllTabs method
    // if (currentTab.feedCount < pageTo && page === 0) {
    //   return;
    // }
    //This "if" need for check if tabs has more feed than 10 and no general
    //General tab no loads feed in getAllTabs request 
    //prevent load from 0 to 10 pagination
    //Because first pagination load from getAllTabs method
    // if (currentTab.id !== 'general') {
    //   const pageForTab = page + 1;
    //   pageFrom = pageForTab * 10;
    //   pageTo = pageFrom + 10;
    // }

    getFeed({
      from: pageFrom,
      to: pageTo,
      tabId
    })
  }

  useEffect(() => {
    console.log(currentTab.feed.length)
    if (currentTab.feed.length === 0) {
      onFeedRequestHandler()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabId])

  if (!isGetFeedLoading && currentTab.feed.length === 0) {
    return (
      <NoDataAtSearch />
    )
  }

  return (
    currentTab && (
      <InfiniteScroll
        dataLength={currentTab.feed.length}
        next={onFeedRequestHandler}
        hasMore={currentPagesForTab.hasMore}
        loader={<CustomLinearProgress />}
        endMessage={
          <p style={{ textAlign: 'center', fontWeight: 300 }}>
            - No more items for load. -
          </p>
        }
        style={{ width: '100%' }}
      >
        <Box className={style.postsWrapper}>
          {currentTab.feed.map(post => <SinglePost key={post.id} post={post} />)}
        </Box>
      </InfiniteScroll>
    )
  )
}

