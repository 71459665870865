import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, postRequest, postRequestExport } from "../../../general/https";
import { DeleteLeadsPayload, DeleteLeadsResponse, ExportLeadsPayload, ExportLeadsResponse, GetLeadListResponse, GetLeadsPayload, GetListOfTeamMembersAndCheckCookiesPayload, GetListOfTeamMembersAndCheckCookiesResponse, GetUploadingSnLinkForUserResponse, MarkLeadsAsReadPayload, MarkLeadsAsReadResponse, SendErrorNotificationPayload, SendErrorNotificationResponse, StopWorkUploadSnLinkPayload, StopWorkUploadSnLinkResponse, UpdateFullLeadInfoPayload, UpdateFullLeadInfoResponse } from "./types";
import { checkErrorStatus } from "../../../general/checkErrorStatus";
import Cookies from "universal-cookie";

export const getLeadList = createAsyncThunk<GetLeadListResponse, GetLeadsPayload>(
  'tableLeadsSlice/getLeadList',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`/lead-information/get-leads`, payload, true)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const deleteLeadsAndUpdatedLeadList = createAsyncThunk<DeleteLeadsResponse, DeleteLeadsPayload>(
  'tableLeadsSlice/deleteLeadsAndUpdatedLeadList',
  async (payload, {rejectWithValue}) => {
    try {
      await postRequest('/lead-information/remove-leads', { leadsIds: payload.selectedRows, isAllSelected: payload.isAllSelected  }, true)
      
      const response = await postRequest(`/lead-information/get-leads`, payload, true) 
      checkErrorStatus(response)
      return response.data;
    } catch(error) {
      return rejectWithValue(error.message)
    }

  }
)

export const markLeadsAsRead = createAsyncThunk<MarkLeadsAsReadResponse, MarkLeadsAsReadPayload>(
  'tableLeadsSlice/markLeadsAsRead',
  async (payload, {rejectWithValue}) => {
    try {
      await postRequest('/lead-information/mark-leads-as-read', { leadsIds: payload.selectedRows, isAllSelected: payload.isAllSelected  }, true)
    
      const response = await postRequest(`/lead-information/get-leads`, payload, true)      
      checkErrorStatus(response)
      return response.data;
    } catch(error) {
      return rejectWithValue(error.message)
    }

  }
)

export const updateFullLeadInfo = createAsyncThunk<UpdateFullLeadInfoResponse, UpdateFullLeadInfoPayload>(
  'tableLeadsSlice/updateFullLeadInfo',
  async (body, {rejectWithValue}) => {
    try {
      const response = await postRequest(`/lead-information/update-full-lead-info`, body, true)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getListOfTeamMembersAndCheckCookies = createAsyncThunk<
GetListOfTeamMembersAndCheckCookiesResponse, GetListOfTeamMembersAndCheckCookiesPayload>(
  'tableLeadsSlice/getListOfTeamMembersAndCheckCookies',
  async ({userInformation}, {rejectWithValue}) => {
    const body: {userUuid?: string, organizationId?: string} = {};
    const organizationId = userInformation.organizationId;
    const userUuid = new Cookies().get('ht');
    if(organizationId === 'none' || !organizationId) {
      body.userUuid = userUuid;
    } else {
      body.organizationId = organizationId
    }
    try {
      const response = await postRequest(`/team-information/getListOfTeamMembersAndCheckCookies`, body , false)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getUploadingSnLinkForUser = createAsyncThunk<GetUploadingSnLinkForUserResponse>(
  'tableLeadsSlice/getUploadingSnLinkForUser',
  async (_, {rejectWithValue}) => {
    try {
      const response = await getRequest('/parsing/get-uploading-sn-link-for-user', true)
      checkErrorStatus(response)
      return response.data;
    } catch(error) {
      return rejectWithValue(error.message)
    }

  }
)

export const sendErrorNotification = createAsyncThunk<SendErrorNotificationResponse, SendErrorNotificationPayload>(
  'tableLeadsSlice/sendErrorNotification',
  async (body, {rejectWithValue}) => {
    try {
      const response = await postRequest('/parsing/send-error-notification-uploading-sn', body, true)
      checkErrorStatus(response)
      return response.data;
    } catch(error) {
      return rejectWithValue(error.message)
    }

  }
)

export const stopWorkUploadSnLink = createAsyncThunk<StopWorkUploadSnLinkResponse, StopWorkUploadSnLinkPayload>(
  'tableLeadsSlice/stopWorkUploadSnLink',
  async (payload, {rejectWithValue}) => {
    try {
      const uploadingLink = payload.uploadingLink;
      const isSnLink = Object.prototype.hasOwnProperty.call(uploadingLink, 'link')

      const body = {
        snLink: isSnLink ? uploadingLink : null,
        csv: !isSnLink ? uploadingLink : null,
      }
      
      const response = await postRequest('/parsing/stop-work-upload-sn-link', body, false)
      checkErrorStatus(response)
      return response.data;
    } catch(error) {
      return rejectWithValue(error.message)
    }
  }
)

export const exportLeads = createAsyncThunk<ExportLeadsResponse, ExportLeadsPayload>(
  'tableLeadsSlice/exportLeads',
  async (body, {rejectWithValue}) => {
    try {
      const response = await postRequestExport('/lead-information/export-leads', body, true)
      const url = window.URL.createObjectURL(response.data);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'leads.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      checkErrorStatus(response)
    } catch(error) {
      console.log('error', error)
      return rejectWithValue(error.message)
    }
  }
)